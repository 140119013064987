<p class="tenants-selection">
  <span class="tenants-selection-label">Tenant:</span>
  <button type="button" class="btn btn-default btn-xs" (click)="onSelect(undefined)">/</button>
  <span class="tenants-selection-item" *ngFor="let tenant of selectedPath">
    <tenants-selection-item #item [tenant]="tenant" (select)="onSelect($event)"></tenants-selection-item>
  </span>
  <tenants-selection-drop-down *ngIf="!(isSelectionForVisualizer && isDomainTenantSelected)" [tenant]="selectedTenant" (select)="onSelect($event)"
                               separator="true"></tenants-selection-drop-down>
  <span class="tenants-selection-description">{{selectedTenant !== undefined ? selectedTenant.description : 'Master-Tenant'}}</span>
  <span *ngIf="isSelectionForVisualizer && !!selectedTenantConfig" class="tenants-selection-description">Language used '{{selectedTenantConfig?.languageTenantId?.split('/').pop()}}'</span>
</p>
