import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
// import 'style-loader!./selection.scss';
import {Tenant, TenantConfig, TenantsService} from '../tenants.service';
import {CdSessionService} from '../../../../theme-ext/services';
import {Event, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tenants-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TenantsSelectionComponent implements OnInit, OnDestroy {
  tenantPreselected: boolean = false;
  private routerEventsSubscription: Subscription;

  get selectedPath(): Tenant[] {
    return this.tenantsService.getSelectedPath();
  }

  get selectedTenant(): Tenant {
    return this.tenantsService.selectedTenant;
  }

  get getUrl(): String {
    return this.router.url;
  }

  get isSelectionForVisualizer(): boolean {
    return this.getUrl.includes('/visualizer/');
  }

  get isDomainTenantSelected(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const tenantId = this.selectedTenant.id;
    return this.getNumSlashesFromTenantId(tenantId) === 2;
  }

  get selectedTenantConfig(): null | TenantConfig {
    return this.tenantsService.selectedTenantConfig;
  }

  @Input()
  set selectedTenant(tenant: Tenant) {
    if (tenant) {
      this.fetchTenantConfig(tenant.id);
    }
    this.tenantsService.selectedTenant = tenant;
    this.tenantsService.getChildren(tenant).then(data => {
      if (data?.length === 1 && this.isSelectionForVisualizer && this.getNumSlashesFromTenantId(tenant.id) < 2) {
        this.selectedTenant = data[0];
      }
    })
  }

  constructor(private tenantsService: TenantsService, private sessionService: CdSessionService, private router: Router) {
    sessionService.subscribeLoggedIn(loggedIn => {
      if (loggedIn === false) {
        this.tenantPreselected = false;
      } else if (loggedIn && !this.tenantPreselected) {
        tenantsService.preselectTenant(null);
        this.tenantPreselected = true;
      }
    });
  }

  ngOnInit() {
    this.fetchTenantConfig(this.selectedTenant?.id || '');
    this.routerEventsSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const tenantId = this.selectedTenant.id;
        if (this.isSelectionForVisualizer && this.getNumSlashesFromTenantId(tenantId) > 2) {
          const tenantIdWithoutLocaleNode = tenantId
            .split('/')
            .slice(0, -1) // Remove last node (= locale node)
            .join('/');
          this.tenantsService.loadTenant(tenantIdWithoutLocaleNode)
            .then(tenant => {
              this.tenantsService.selectedTenant = tenant;
            });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  onSelect(tenant: Tenant): void {
    this.selectedTenant = tenant;
  }

  private getNumSlashesFromTenantId(tenantId: string) {
    return tenantId.replace(/[^/]/g, '').length;
  }

  private fetchTenantConfig(tenantId: string) {
    if (this.isSelectionForVisualizer) {
      if (this.getNumSlashesFromTenantId(tenantId) < 2) {
        this.tenantsService.selectedTenantConfig = null;
        return;
      }
      let tenantIdWithoutLocaleNode = tenantId;
      if (this.getNumSlashesFromTenantId(tenantId) > 2) {
        tenantIdWithoutLocaleNode = tenantId
          .split('/')
          .slice(0, -1) // Remove last node (= locale node)
          .join('/');
      }

      const normalizedTenant = tenantIdWithoutLocaleNode.replace(/\//g, '+');
      this.tenantsService.getLanguageUsedForTenant(normalizedTenant).then((tenantConfig: TenantConfig) => {
        this.tenantsService.selectedTenantConfig = tenantConfig;
      }).catch(e => {
        this.tenantsService.selectedTenantConfig = null;
      });
    }
  }
}
