import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Tenant, TenantsService} from '../tenants.service';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tenants-selection-drop-down',
  templateUrl: './selectionDropDown.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TenantsSelectionDropDownComponent {
  @ViewChild('popover') public popover: NgbPopover;

  private _separator = false;

  private _tenant: Tenant;
  children: Tenant[];

  get separator() {
    return this._separator;
  }

  @Input()
  set separator(separator: boolean) {
    this._separator = separator;
  }

  @Output()
  private select: EventEmitter<Tenant> = new EventEmitter<Tenant>();

  get tenant(): Tenant {
    return this._tenant;
  }

  @Input()
  set tenant(tenant: Tenant) {
    this._tenant = tenant;
    this.tenantsService.getChildren(this._tenant).then(children => {
      this.children = children;
    });
  }

  fillChildren() {
    if (!this.children?.length) {
      this.tenantsService.getChildren(this._tenant).then(children => {
          this.children = children;
      });
    }
  }

  constructor(private tenantsService: TenantsService) {
  }

  onSelect(tenant: Tenant): void {
    //this.popover.close();
    this.select.emit(tenant);
  }
}
